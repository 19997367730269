<template>
	<v-row xs12 class="text-center pa-4">
		<v-col>
			<h3>Sign In</h3>
			<p class="mt-4 title">Please sign into your account to continue.</p>
			<v-progress-circular v-if="redirecting" indeterminate color="grey" class="mt-7" />
			<r-btn v-else large class="mt-4" @click="signIn">Sign In</r-btn>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: {
		returnUrl: String
	},
	data() {
		return {
			redirecting: _REALM.STORE.accountStore.isLoggedIn(),
			inIframe: _REALM.TOOLS.inIframe()
		};
	},
	methods: {
		signIn() {
			this.redirecting = true;
			_REALM.STORE.accountStore.redirectToSSO();
		}
	}
};
</script>
